import style from "./style.module.scss";

interface GradientCardProps {
  angle?: number;
  colorA: string;
  colorB: string;
  children?: JSX.Element[] | JSX.Element;
}

const GradientCard = ({ angle = 45, colorA, colorB, children }: GradientCardProps) => {
  const background = `linear-gradient(${angle}deg, ${colorA} 0%, ${colorB} 100%)`;

  return (
    <div className={style.card} style={{ background }}>
      {children}
    </div>
  );
};

export default GradientCard;
