import React from "react";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { signOut, useSession } from "next-auth/client";

import style from "./style.module.scss";

export default function Footer() {
  const { t } = useTranslation("common");

  const [session] = useSession();
  const Separator = () => <> • </>;

  let content;

  if (!session) {
    content = (
      <>
        <Link href="/signin">
          <a>{t("sign-in")}</a>
        </Link>
        <Separator />
        <Link href="/signup">
          <a>{t("sign-up")}</a>
        </Link>
      </>
    );
  } else {
    content = (
      <>
        <Link href="/dashboard">
          <a>{t("dashboard")}</a>
        </Link>
        <Separator />
        <Link href="#">
          <a onClick={() => signOut()}>{t("sign-out")}</a>
        </Link>
      </>
    );
  }

  return (
    <div className={style.footer}>
      <div className={style.logo}>
        <img src="../../logos/logo_light.svg" />
      </div>
      <div className={style.brand}>© 2021 Bilink, {t("all-rights-reserved")}</div>

      <div className={style.nav}>
        <Link href="/">
          <a>{t("home")}</a>
        </Link>
        <Separator />
        {content}
      </div>
    </div>
  );
}
