import Image from "next/image";
import { ChangeEvent, MouseEvent, KeyboardEvent, useRef, useEffect, useState } from "react";

import style from "./style.module.scss";

interface InputFieldProps {
  type: string;
  label?: string;
  value?: string;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  placeholder?: string;
  valueFilter?: RegExp;
  displayLabel?: boolean;
  displayButton?: boolean;
  onEnterDown?: (value: string) => void;
  onValueChanged?: (value: string) => void;
  onButtonClicked?: (value: string) => void;
}

export default function InputField({
  type,
  value,
  label,
  required,
  minLength = 0,
  maxLength = 255,
  valueFilter,
  placeholder,
  displayLabel,
  displayButton,
  onEnterDown,
  onValueChanged,
  onButtonClicked,
}: InputFieldProps): JSX.Element {
  const claimButtonRef = useRef<HTMLButtonElement>(null);
  const claimInputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState(value);

  const handleOnValueChanged = (event: ChangeEvent<HTMLInputElement>) => {
    if (valueFilter !== null) {
      event.target.value = event.target.value.replace(valueFilter as RegExp, "");
      setInputValue(event.target.value);
    }

    if (claimButtonRef.current && minLength > 0) {
      const showButton = event.target.value.length > minLength - 1;
      claimButtonRef.current.style.opacity = showButton ? "1" : "0";
      claimButtonRef.current.style.transform = showButton ? "scale(1)" : "scale(0)";
    }

    if (onValueChanged) onValueChanged(claimInputRef.current?.value as string);
  };

  const handleOnEnterKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (onEnterDown) onEnterDown(claimInputRef.current?.value as string);
    }
  };

  const handleOnButtonClicked = (e: MouseEvent<HTMLButtonElement>) => {
    if (onButtonClicked) onButtonClicked(claimInputRef.current?.value as string);
  };

  useEffect(() => {}, []);

  return (
    <div className={style.inputField}>
      {displayLabel && <div className={style.label}>{label}</div>}
      <input
        type={type}
        autoComplete="on"
        value={inputValue || ""}
        ref={claimInputRef}
        required={required}
        minLength={minLength}
        maxLength={maxLength}
        placeholder={placeholder}
        onKeyDown={handleOnEnterKeyDown}
        onChange={handleOnValueChanged}
      />
      {displayButton && (
        <button ref={claimButtonRef} onClick={handleOnButtonClicked}>
          <Image alt="" src="/images/right-arrow.svg" width="38" height="38" />
        </button>
      )}
    </div>
  );
}
