import React from "react";
import { PulseLoader } from "react-spinners";

import style from "./style.module.scss";

interface MainButtonProps {
  text: string;
  primary?: boolean;
  loading?: boolean;
  onClick?: () => void;
  type?: "button" | "submit" | "reset" | undefined;
}

export default function MainButton({ text, type, loading, primary, onClick }: MainButtonProps) {
  const background = primary
    ? "linear-gradient(0deg, #eee 0%, #fff 100%)"
    : "linear-gradient(0deg, #44a1c0 0%, #56ccf2 100%)";

  const color = primary ? "#000" : "#fff";

  const handleOnClick = () => {
    if (onClick) onClick();
  };

  return (
    <button type={type} className={style.button} style={{ background, color }} onClick={handleOnClick}>
      {loading ? <PulseLoader size="8px" margin="4px" color={color} /> : text}
    </button>
  );
}
