import React, { useEffect, useState } from "react";
import router from "next/router";
import Layout from "../app/layout";
import { event } from "../libs/gtag";
import { NextPageContext } from "next";
import { useSession } from "next-auth/client";
import { i18n, useTranslation } from "next-i18next";
import Spacer from "../components/ui-kit/spacer";
import InputField from "../components/ui-kit/input-field";
import MainButton from "../components/ui-kit/main-button";
import GradientCard from "../components/ui-kit/gradient-card";
import SlideInWrapper from "../components/ui-kit/slide-in-wrapper";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import style from "../../styles/index.module.scss";
import LoadingPanel from "@/components/ui-kit/loading-panel";

export default function Home() {
  const { t } = useTranslation(["common"]);

  const [session, loading] = useSession();
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    const lang = navigator.languages[0].substring(0, 2);
    i18n?.changeLanguage(lang);
  }, []);

  const handleOnKeyDown = (value: string) => {
    event({
      action: "claim_via_enter_key",
      category: "Claim Link",
      label: value,
    });

    setButtonLoading(true);

    router.push(`/signup?handle=${value}`);
  };

  const handleOnClick = (value: string) => {
    event({
      action: "claim_via_button",
      category: "Claim Link",
      label: value,
    });

    setButtonLoading(true);

    router.push(`/signup?handle=${value}`);
  };

  const handeSignInClick = () => {
    event({
      action: "homepage_sign_in_click",
      category: "Sign in Click",
    });

    router.push("/signin");
  };

  const handeSignUpClick = () => {
    event({
      action: "homepage_sign_up_click",
      category: "Sign up Click",
    });

    router.push("/signup");
  };

  const handleGoToDashboard = async () => {
    setButtonLoading(true);
    event({
      action: "homepage_dashboard_click",
      category: "Dashboard Click",
    });
    await router.push("/dashboard");
  };

  return loading ? (
    <LoadingPanel />
  ) : (
    <Layout homepage>
      <SlideInWrapper>
        <div className={style.area}>
          <div className={style.left}>
            <div className={style.header}>
              <div>{t("header-1")}</div>
              <div className={style.detail}>{t("detail-1")}</div>
            </div>
            <GradientCard colorA="#eb5757" colorB="#bb6bd9">
              {session ? (
                <div>
                  <h2 style={{ textShadow: "0px 1px 2px #222" }}>{t("welcome-back")}</h2>
                  <MainButton
                    primary
                    loading={buttonLoading}
                    text={t("go-to-dashboard")}
                    onClick={handleGoToDashboard}
                  />
                </div>
              ) : (
                <div>
                  <h2 style={{ textShadow: "0px 1px 2px #222" }}>{t("claim-your-link-now")}</h2>
                  <InputField
                    type="text"
                    displayLabel
                    minLength={3}
                    maxLength={20}
                    displayButton
                    label="bilink.im/"
                    placeholder={t("your-link-ph")}
                    valueFilter={/[^a-zA-Z0-9_]/}
                    onEnterDown={handleOnKeyDown}
                    onButtonClicked={handleOnClick}
                  />
                  <h2 style={{ textShadow: "0px 1px 2px #222" }}>{t("or")}</h2>
                  <MainButton text={t("sign-in")} onClick={handeSignInClick} loading={buttonLoading} />
                </div>
              )}
            </GradientCard>
            <Spacer height="10px" />
          </div>
          <img alt="" className={style.phone} src="../../images/phone.png" />
        </div>
      </SlideInWrapper>
      <Spacer height="100px" />
      <SlideInWrapper>
        <div className={style.area}>
          <div className={style.left}>
            <div className={style.header}>
              <div>{t("header-2")}</div>
              <div className={style.detail}>{t("detail-2")}</div>
            </div>
          </div>
          <img alt="" className={style.circles} src="../../images/circles.png" />
        </div>
      </SlideInWrapper>
      <Spacer height="100px" />
      <SlideInWrapper>
        <div className={style.area}>
          <div className={style.left}>
            <div className={style.header}>
              <div>{t("header-3")}</div>
              <div className={style.detail}>{t("detail-3")}</div>
            </div>
          </div>
          <img alt="" className={style.circles} src="../../images/socials.png" />
        </div>
      </SlideInWrapper>
      {session ? (
        <></>
      ) : (
        <SlideInWrapper>
          <div className={style.action}>
            <div className={style.text}>{t("dont-have-a-link")}</div>
            <MainButton text={t("become-a-member")} onClick={handeSignUpClick} primary />
          </div>
        </SlideInWrapper>
      )}
    </Layout>
  );
}

export async function getServerSideProps({ locale, res }: NextPageContext) {
  res?.setHeader("set-cookie", [`NEXT_LOCALE=${locale}`]);

  return {
    props: {
      ...(await serverSideTranslations(locale as string, ["common"])),
    },
  };
}
