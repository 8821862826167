import React from "react";
import Header from "./header";
import Main from "./main";
import Footer from "./footer";

import style from "./style.module.scss";

interface LayoutProps {
  homepage?: boolean;
  children?: JSX.Element | JSX.Element[] | null;
}

export default function Layout({ homepage, children }: LayoutProps) {
  const className = style.layout + " " + (homepage ? style.homepage : "");

  return (
    <div className={className}>
      <Header />
      <Main>{children}</Main>
      <Footer />
    </div>
  );
}
