import React from "react";
import Link from "next/link";

import style from "./style.module.scss";

export default function Header() {
  return (
    <div className={style.header}>
      <Link href="/">
        <img className={style.logo} src="../../logos/logo_color.svg" />
      </Link>
    </div>
  );
}
