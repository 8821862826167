import * as React from "react";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";

interface SlideInWrapperProps {
  direction?: "y" | "x";
  value?: string;
  duration?: number;
  withOpacity?: boolean;
  children?: JSX.Element[] | JSX.Element;
}

function SlideInWrapper({
  direction = "y",
  value = "1rem",
  duration = 1,
  withOpacity = true,
  children,
  ...restProps
}: SlideInWrapperProps) {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  React.useEffect(() => {
    if (inView) {
      controls.start("slideIn");
    } else {
      controls.start("slideOut");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="slideOut"
      transition={{ type: "spring", duration: duration, bounce: 0.15 }}
      variants={{
        slideOut: { [direction]: value, opacity: withOpacity ? 0 : 1 },
        slideIn: { [direction]: 0, opacity: 1 },
      }}
      {...restProps}
    >
      {children}
    </motion.div>
  );
}

export default SlideInWrapper;
