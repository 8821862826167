import React from "react";
import { Spinner } from "@/components/svg-icons/icons";

import styles from "./style.module.scss";

interface IProps {
  style?: React.CSSProperties;
}

const LoadingPanel = ({ style }: IProps): JSX.Element => {
  return (
    <div className={styles.container} style={style}>
      <Spinner className={styles.spinner} />
    </div>
  );
};

export default LoadingPanel;
